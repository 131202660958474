html {
  max-width: 100%;
  overflow-x: hidden;
  overflow-y: scroll;
}

body {
  margin: 0;
  font-family: 'Montserrat', "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #394051;
  letter-spacing: 1.4px;
  line-height: 0;
  max-width: 100%;
  overflow-x: hidden;
}

.clickable {
  cursor: pointer;
}

.nav {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1;
  background: #394051;
  box-shadow: 0 0 6px -2px;
}

.nav > a {
  color: #E6F5F7;
  width: 180px;
  text-align: center;
  font-weight: 500;
  line-height: 40px;
  height: 40px;
  text-decoration: none;
}

.nav > svg {
  flex-shrink: 0;
}

.sidebar {
  position: fixed;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 1200;
  transition: left 0.4s;
}

.sidebar-overlay {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  background-color: black;
  opacity: 0.5;
}

.sidebar-container {
  position: relative;
  height: 100%;
  width: 80%;
  max-width: 260px;
  background-color: #394051;
  box-shadow: #394051 5px;
  overflow-y: scroll;
}

.sidebar-list {
  display: flex;
  flex-direction: column;
  padding-top:6px
}

.sidebar-list-item {
  width: 100%;
  height: 50px;
  text-decoration: none;
  display: flex;
  justify-content: stretch;
  cursor: pointer;
}

.sidebar-list-subitem {
  width: 100%;
  text-indent: 30px;
  height: 36px;
  line-height: 36px;
  font-size: 15px;
  font-weight: 400;
  color: #E6F5F7;
  text-decoration: none;
  display: flex;
  justify-content: stretch;
  cursor: pointer;
}

.sidebar-list-item-text {
  min-width: 100px;
  text-indent: 16px;
  height: 50px;
  line-height: 50px;
  font-size: 18px;
  font-weight: 500;
  color: #E6F5F7;
  text-decoration: none;
  display: flex;
  justify-content: stretch;
  cursor: pointer;
}

.sidebar-list-item-expand {
  width: 100%;
  margin-left: 10px;
  line-height: unset;
  display: flex;
  align-items: center;
}

.sidebar-expand-image {
  height: 24px;
  cursor: pointer;
  transition: transform 0.2s;
}

.sidebar-list-subitem > .sidebar-list-item {
  text-indent: 32px;
}

.image-nav {
  padding: 10px 0;
}

.image-nav > img {
  height: 80px;
}

.generic-page {
  position: relative;
  min-width: 320px;
  min-height: calc(100vh - 280px);
  padding-bottom: 180px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  overflow-y: auto;
  overflow-x: hidden;
  background-color: #E6F5F7;
}

#arrow-up img {
  width: 40px;
}

.footer {
  position: absolute;
  width: calc(100vw - 80px);
  height: 48px;
  bottom: 40px;
  display: flex;
  justify-content: center;
}

.footer > a {
  z-index: 1;
}

.footer > svg {
  position: absolute;
  top: 19px;
}

.footer-bar-wrapper {
  position: absolute;
  width: calc(100vw - 80px);
  overflow: hidden;
  top: 19px;
}

.home {
  flex: 1;
  align-items: center;
  width: 100%;
}

.work {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.work .image-deco-title {
  width: 100%;
  max-width: 400px;
}

.work-h2 {
  font-weight: 500;
  font-size: 26px;
  line-height: 45px;
}

.work-h3 {
  margin-top: 20px;
  font-weight: 500;
  font-size: 18px;
  line-height: 40px;
}

.work-h4 {
  max-width: 300px;
}

.work-grid {
  margin-top: 60px;
  max-width: 1000px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.work-grid-item {
  position: relative;
  display: flex;
  flex-direction: column-reverse;
  align-items: center;
  margin: 20px 10px;
  max-width: 450px;
  text-align: center;
  overflow: hidden
}

.work-grid-overlay {
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  opacity: 0;
  transition: opacity 0.3s;
  background-color: white;
}

.work-grid-item:hover > .work-grid-overlay {
  opacity: 0.7;
}

html.touch .work-grid-item > .work-grid-overlay {
  opacity: 1 !important;
  background: linear-gradient(rgba(255, 255, 255, 0) 50%, white 90%);
  background-color: unset;
}

.work-grid-lead-in {
  position: absolute;
  bottom: 20px;
}

.work-grid-item:hover > .work-grid-lead-in {
  transition: left 0.3s;
  transition-delay: 0.3s;
}

.work-grid-item:hover > .work-grid-lead-in, html.touch .work-grid-item > .work-grid-lead-in {
  left: 0 !important;
}

.work-grid-item-label {
  position: absolute;
  bottom: 0px;
  left: 15px;
  opacity: 0;
  color: #394051;
  font-size: 30px;
  font-weight: 500;
  line-height: 30px;
}


.work-grid-item:hover > .work-grid-item-label {
  transition: opacity 0.3s, bottom 0.3s;
  transition-delay: 0.6s;
}

.work-grid-item:hover > .work-grid-item-label, html.touch .work-grid-item > .work-grid-item-label {
  bottom: 30px;
  opacity: 1;
}

.about {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.about .deco-title {
  font-weight: 500;
  font-size: 18px;
  line-height: 30px;
}

.deco-title ul {
  margin-block-start: 10px;
  padding-inline-start: 20px;
}

.deco-title li:not(:first-child) {
  margin-top: 15px;
}

.about-body {
  min-height: 400px;
  margin-top: 50px;
  width: 100%;
  max-width: 1140px;
  position: relative;
}

.about-details {
  line-height: 30px;
  margin: 0 20px;
  position: relative;
}

.image-about {
  margin: 20px 10px;
  width: calc(40% - 20px);
  max-width: 330px;
}

.lead-in-left, .lead-in-right {
  top: -34px;
}

.about .lead-in-left {
  top: 80px;
}

.about .lead-in-left {
  position: absolute;
  right: 50%;
}

.lead-in-left {
  position: absolute;
  right: 40%;
}

.lead-in-right {
  position: absolute;
  left: 40%;
}

.contact {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.contact .deco-title {
  font-weight: 500;
  font-size: 18px;
  line-height: 30px;
}

.contact-h3 {
  font-weight: 500;
  font-size: 22px;
  line-height: 40px;
}

.contact-box {
  position: relative;
  margin-top: 70px;
  width: 600px;
  max-width: calc(100vw - 40px);
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
}

.contact-form {
  position: relative;
  padding: 20px;
  display: flex;
  width: 100%;
  height: 100%;
  flex-direction: column;
  background-color: #f3a98d;
  border: 2px solid #394051;
}

.contact-drop-line {
  border-radius: 2px;
  position: absolute;
  top: -10px;
  left: -10px;
  width: 100%;
  height: 100%;
  user-select: none;
  pointer-events: none;
}

.contact-form input, .contact-form textarea  {
  border-top-style: hidden;
  border-right-style: hidden;
  border-left-style: hidden;
  border-bottom-style: hidden;
  background-color: white;
  font-family: 'Montserrat', "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  font-size: 12px;
  color: #394051;
  width: 100%;
  height: 35px;
  padding: 10px;
  border-radius: 0;
}

.contact-form textarea {
  height: 100px;
  resize: none;
}

.contact-form input:focus, .contact-form textarea:focus  {
  outline: none;
}

.contact-form button {
  background-color: white;
  font-family: 'Montserrat', "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  font-size: 12px;
  padding: 8px 10px;
  color: #394051;
  background:white;
  box-shadow: 0px 0px 0px transparent;
  border: 0px solid transparent;
  text-shadow: 0px 0px 0px transparent;
  cursor: pointer;
}

.contact-row {
  display: flex;
  margin: 10px 0;
  align-items: center;
}

.contact-row > input:not(:first-child), .contact-row > .contact-error-message {
  margin-left: 20px;
}

.photo-credits {
  margin: 10px 40px;
}

.photo-credits-text {
  font-size: 12px;
  font-weight: 500;
  line-height: 16px;
  margin: 2px 3px;
  letter-spacing: -0.3px;
  font-style: italic;
  display: inline-block;
}

.photo-credits-text > a {
  color: #394051;
}

.ui.text.container {
  margin-left: auto!important;
  margin-right: auto!important;
}

.ui.text.container.narrow-container {
  max-width: 450px !important;
}

.image-container {
  position: relative;
  overflow: hidden;
}

.image-container > img {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

.project-page {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  padding-bottom: 160px;
  background-color: #E6F5F7;
}

.page-header {
  margin-top: 50px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.page-h1 {
  position: relative;
  height: 330px;
}

.page-h1-secondary-border {
  border-left: 2px solid #394051;
  border-top: 2px solid #394051;
  position: absolute;
  height: 80%;
  width: 40%;
  top: 0px;
  left: -20px;
}

.page-h1-content {
  border: 2px solid #394051;
  margin-top: 30px;
  width: 700px;
  height: calc(100% - 30px);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 200px;
}

.page-h1-text {
  color: #394051;
  padding: 4px;
  font-size: 120px;
  font-weight: 600;
  position: relative;
  white-space: nowrap;
}

.page-h2-text {
  color: #394051;
  padding: 4px;
  font-size: 80px;
  font-weight: 600;
  position: relative;
  margin: 20px;
  white-space: nowrap;
}

.slideshow {
  position: fixed;
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  z-index: 1000;
}

.slideshow-overlay {
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: black;
  opacity: 0.5;
}

.slideshow-container {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100vw;
  max-width: 1700px;
  max-height: 100vh;
  margin: 0 auto;
  background-color: #E6F5F7;
}

.slideshow-image {
  max-height: 100vh;
  max-width: 1700px;
}

.slideshow-image[src$=".svg"] {
  width: 100%;
}

.slideshow-title {
  position: absolute;
  top: 16px;
  width: 100%;
  text-align: center;
  line-height: 30px;
  font-size: 16px;
  font-weight: 500;
}

.slideshow-side {
  position: absolute;
  height: 200px;
  width: 3em;
  padding: 0 25px;
  opacity: 0.2;
  transition: opacity 0.2s;
  cursor: pointer;
  touch-action: manipulation;
}

.slideshow-side.left {
  left: 0;
  border-top-right-radius: 50px;
  border-bottom-right-radius: 50px;
}

.slideshow-side.right {
  right: 0;
  border-top-left-radius: 50px;
  border-bottom-left-radius: 50px;
}

.slideshow-side:hover, html.touch .slideshow-side {
  /* background-color: rgba(230,245,247, 0.2); */
  opacity: 1;
}

.slideshow-close {
  position: absolute;
  top: 0;
  right: 0;
  width: 2em;
  padding: 20px;
  opacity: 0.2;
  transition: opacity 0.2s;
  cursor: pointer;
}

.slideshow-close:hover, html.touch .slideshow-close {
  opacity: 1;
}

.slideshow-dots {
  position: absolute;
  bottom: 20px;
}

.shadow-text {
  position: absolute;
  color: transparent;
  -webkit-text-stroke: 2px #394051;
  padding: 4px;
  top: -4px;
  left: 4px;
  width: 100%;
}

.header-container {
  position: relative;
  display: flex;
  justify-content: space-between;
  margin: 120px 20px 0;
}

.title {
  display: flex;
  flex-direction: column;
  position: relative;
}

.title-text {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #394051;
  color: white;
  text-align: center;
  font-size: 55px;
  font-weight: 600;
  line-height: 50px;
  z-index: 1;
}

.description {
  display: flex;
  flex-direction: column;
  position: relative;
  margin-left: 20px;
}

.description-title {
  margin-top: 5px;
  font-size: 24px;
  font-weight: 500;
}

.description-main-text {
  margin: 5px 0 20px;
  font-size: 18px;
  font-weight: 500;
}

.generic-h2 {
  font-size: 25px;
  font-weight: 600;
  line-height: 32px;
  letter-spacing: 0px;
}

.generic-h3 {
  font-size: 22px;
  font-weight: 500;
  line-height: 26px;
  letter-spacing: 0px;
}

.generic-h4 {
  font-size: 18px;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0px;
}


.deco {
  display: flex;
  flex-direction: row;
  padding-top: 50px;
  position: relative;
  width: 800px;
  max-width: 100%;
  margin-bottom: 40px;
}

.deco-title {
  margin-left: 180px;
  width: 100%;
  line-height: 1.4282em;
  display: flex;
  flex-direction: column;
}

.deco .section:not(:last-child) {
  margin-bottom: 20px;
}

.deco .section-title {
  margin-top: 5px;
  font-size: 20px;
  font-weight: 500;
}

.deco .section-main-text {
  margin: 5px 0 0;
  font-size: 15px;
  font-weight: 400;
}

.image-grid {
  min-height: 400px;
  margin: 40px 0px;
  width: 100%;
  max-width: 1100px;
  display: flex;
  position: relative;
  justify-content: center;
  flex-wrap: wrap;
}

.image-half-width {
  margin: 20px 10px 0;
  width: calc(50% - 20px);
  max-width: 550px;
}

.image-half-width-container {
  position: relative;
  height: auto;
  overflow: hidden;
  padding-top: 64.7%;
}

img.set-aspect {
  display: block;
  position: absolute;
  max-height: 100%;
  max-width: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.image-flip .image-half-width-container img.set-aspect {
  transition: transform 0.3s linear;
}

.image-flip .image-half-width-container img.set-aspect.active {
  transition: transform 0.3s linear 0.3s;
}

.icon-flip {
  position: absolute;
  width: 40px;
  border-radius: 20px;
  top: 20px;
  right: 20px;
  z-index: 3;
  opacity: 0 !important;
  transition: opacity 0.4s;
}

.image-flip:hover .icon-flip {
  opacity: 1 !important;
}

.image-full-width {
  margin: 20px 40px 0;
  width: 100%;
}

.image-full-width > img {
  width: 100%;
}

.image-narrow-width {
  margin: 0px;
  width: 50%;
  max-width: 400px;
}

.image-narrow-width > img {
  width: 100%;
}

.image-accent {
  position: absolute;
}

.image-accent > img {
  width: 100%;
}

.image-deco {
  position: absolute;
  bottom: -20px;
  margin: 0px;
  width: 160px;
}

.image-deco > img {
  width: 100%;
}

.image-deco-title {
  margin: 0 0 20px;
  width: 100%;
  max-width: 320px;
  height: 117px;
  display: flex;
  align-items: flex-end;
}

.image-deco-title > img {
  width: 100%;
}

.about > .deco {
  width: 700px;
}

.about .image-deco-title {
  max-width: 320px;
}

.cards .image-deco-title {
  max-width: 295px;
}

.combo .image-deco-title {
  max-width: 498px;
}

.contact > .deco {
  width: 700px;
}

.contact .image-deco-title {
  max-width: 415px;
}

.fem-dep .image-deco-title {
  max-width: 651px;
}

.gift-box .image-deco-title {
  max-width: 429px;
}

.hairpix .image-deco-title {
  max-width: 420px;
}

.heist .image-deco-title {
  max-width: 300px;
}

.home > .deco {
  width: 645px;
}

.home .image-deco-title {
  max-width: 286px;
}

.reveal .image-deco-title {
  max-width: 342px;
}

.tournament .image-deco-title {
  max-width: 452px;
}

.work > .deco {
  width: 645px;
}

.work .image-deco-title {
  max-width: 287px;
}

.about > .deco {
  width: 680px;
}

.about > .deco {
  width: 680px;
}

.image-footer {
  width: 48px;
  margin: 0 20px;
}

.image-footer > img {
  width: 100%;
}

@media only screen and (max-width: 1700px) {
  .slideshow-image {
    max-width: 100vw;
  }
}


@media only screen and (max-width: 1024px) {
  .hidden-on-mobile {
    display: none;
  }

  .about > .image-wrapper {
    width: calc(100% - 100px);
    margin: 25px auto;
  }

  .contact > .image-wrapper {
    width: calc(100% - 70px);
    margin: 25px auto;
  }

  .image-full-width {
    margin: 20px 0 0;
    width: 100%;
  }
}

@media only screen and (max-width: 600px) {

  .deco {
    padding-top: 30px;
  }

  .deco-title {
    text-align: center;
    align-items: center;
  }

  .home .generic-h3 {
    max-width: 300px;
  }

  .work {
    min-height: 2000px;
  }

  .work .generic-h3 {
    max-width: 300px;
    letter-spacing: -0.6px;
  }

  .about .generic-h2 {
    max-width: 300px;
  }

  .deco-title ul {
    text-align: left;
    padding-inline-start: 40px;
  }

  .about-body > .lead-in-left {
    display: none;
  }

  .work-grid {
    margin-top: 20px
  }

  .work-grid-item {
    margin: 0 0 20px;
    height: 64.706vw;
  }

  .about-details {
    margin-top: 0 !important;
    text-align: left !important;
    margin: 0 20px;
  }

  .image-grid {
    margin-top: 0;
    min-height: unset;
  }

  .image-half-width {
    max-width: unset;
  }

  .image-about {
    float: none !important;
    margin: 20px auto;
    width: 100%;
    max-width: 300px;
  }

  .lead-in-left, .lead-in-right {
    top: -14px;
  }

  .about .lead-in-left {
    top: -40px;
  }

  .deco {
    margin: 0 10px;
    max-width: calc(100% - 20px);
  }

  .deco-title {
    margin-left: 0;
  }
  
  .image-deco-title {
    margin: 0 auto 20px;
  }

  .image-deco {
    display: none;
  }

  .image-half-width {
    margin: 20px 0 0;
    width: 100%;
  }

  .image-footer {
    margin: 0 5px;
  }

  .slideshow-close {
    width: 1.5em;
    padding: 10px;
  }

  .slideshow-side {
    width: 1.5em;
    padding: 0 12px;
  }
}

@media only screen and (orientation:portrait) and (max-width: 768px) {
  html.touch .slideshow{
    height: 100vw;
    width: 100vh;
    transform: rotate(90deg);
    top: calc((100vh - 100vw) / 2);
    left: calc((100vw - 100vh) / 2);
  }

  html.touch .slideshow-container {
    width: 100%;
    max-height: 100%;
    max-width: 100%;
  }

  html.touch .slideshow-image {
    max-height: 100vw;
    max-width: 100vh;
  }
}